// The file contents for the current environment will overwrite these during build.
// The build system defaults to the staging environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  KEYCLOAK_SERVER_ADDR: 'https://dev-auth.pjm.gob.ar',
  KEYCLOAK_AUTH_ADDRESS:'https://dev-auth.pjm.gob.ar/auth/realms/devops/protocol/openid-connect/auth?client_id=ged&nonce=te7s5t9t&response_mode=fragment&response_type=code&login=true&redirect_uri=https://dev-med.pjm.gob.ar/',
  KEYCLOAK_REALM: 'devops',
  KEYCLOAK_CLIENT_ID: 'ged',
  VERSION:2,
  //SERVER_FRONT_ADDR: 'https://dev-med.pjm.gob.ar//',
  SERVER_FRONT_ADDR: 'https://dev-med.pjm.gob.ar/',
  SERVER_FRONT_ADDR_ENCODED: 'https%3A%2F%dev-med.pjm.gob.ar%2F%2F',
  STICH_API_ADDR: 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/nomenclador-fekfu/service/operador/incoming_webhook/',
  KEYCLOAK_AUTH_ADDRESS_ENC:'https%3A%2F%2Fdev-auth.pjm.gob.ar%2Fauth%2Frealms%2Fdevops%2Fprotocol%2Fopenid-connect%2Fauth%3Fclient_id%3Dged%26nonce%3Dte7ds5t9t%26response_mode%3Dfragment%26response_type%3Dcode%26login%3Dtrue%26redirect_uri%3Dhttps%3A%2F%2Fdev-med.pjm.gob.ar%2F',
  SERVER_BACK_ADDR: 'https://dev-backend.pjm.gob.ar/ged-backend-cloud-master/',
  SHORT_URL_DOWNLOAD:"https://dev-s.pjm.gob.ar/meed/?short_id=",
  NETWORK_CONN_ISSUE_MESSAGE:"Parece hay problemas con la red del poder judicial y la MEED no puede conectarse a internet."
  //SERVER_BACK_ADDR:'https://dev-backend.pjm.gob.ar/ged-backend-cloud-jwt/'
  //SERVER_BACK_ADDR:'https://backend.pjm.gob.ar/ged-backend-cloud/'

};
 