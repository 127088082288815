import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { environment } from './../../environments/environment';
//import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  constructor(private http:HttpClient) { }
  checkVersion()
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'skip-interceptor': 'true'
      })
    };
    
    const fragment =  'version/?frontend_app=MEED' ;
    
    const server =  environment.SERVER_BACK_ADDR +fragment;

   return  this.http.get(server, httpOptions)
   //ged-backend-cloud-master/novedades
  }

  checkBackendReach()
  {
    return  this.http.get(environment.SERVER_BACK_ADDR+"estado/info", {responseType: 'text'})
  }

  // https://dev-backend.pjm.gob.ar/ged-backend-cloud-files-upload/version/info


}
