        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button mat-raised-button (click)='run_login_process()'><i class="mdi mdi-lock-open-outline m-r-5 mdi-18px"></i>Login</button>-->


<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button>
    <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>

