import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {VersionCheckService} from '../services/version-check.service';
import { Observable } from 'rxjs';
import {Router,NavigationExtras} from '@angular/router'
import { environment } from './../../environments/environment';
/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  activityCounter=0
constructor(private authService:AuthService,
  private router:Router,
  private versionCheckService:VersionCheckService){}
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
        //console.log("INTERCEPTOR")
        //console.log(req.headers);
        
       if (req.headers.get("skip-interceptor")=="true"){
        //console.log("skip")
       return next.handle(req);}
      //console.log("interceptor activity counter="+this.activityCounter)
       this.activityCounter+=1
       if(this.activityCounter % 10 == 0) //will trigger version check every 10 request aproximate
       {
        this.versionCheckService.checkVersion().subscribe(r=>
          {
            //console.log("getting version");
    
            let _temp_ver =this.authService.getFrontVersion()
            let _remote_ver=r['data'][0]['version']
           
            //console.log("local version is="+environment.VERSION)
        
            //console.log("remote version is ="+_remote_ver );
        
            if(_temp_ver!=_remote_ver)
            {
              alert("Nueva Version Detectada! ,click OK para actualizar..")
              window.location.reload(true);
            }
          })

         
       }
        let navigationExtras: NavigationExtras = {
          queryParams: {
              "origin": "interceptor"      
          }
      };
        if(localStorage.getItem('access_token')!==null)
        {
           // //console.log("has some jwt stored.")
            if (this.authService.checkTokenExpiry()){
              //console.log('token has  expired');
            
              this.router.navigate(['main'],navigationExtras)
            }else
            {
              const authReq = req.clone( { headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token') ) });
              return next.handle(authReq)
              
                //jwt expired

            }
        }
        ////console.log("////////////////////interceptor//////////////////////")
    return next.handle(req);
  }
}