import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
constructor(){}
  // run_login_process()
  // {
  //   window.location.href = this.configService.getAuthAddress();
  // }
}
