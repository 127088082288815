import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
// import { map, retry, catchError } from 'rxjs/operators';
 import { Observable, of as observableOf,Subject,BehaviorSubject } from 'rxjs';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private configService: ConfigService,
    private http: HttpClient) { }


  private subject_ = new Subject<any>();
  private subject_meta4 = new Subject<any>();
  //just a little piece of advice here, for the future me who will forget: BehaviourSubject keeps the last emitted values for any NEW SUBSCRIBER (aka new subscriber)
  //therefore if a lets say http callback has already been emitted, it will be available for FUTURE subscribers
  private subject_oh_behave = new BehaviorSubject("empty");

  body = {};
  server = '';
  isFirstLog: boolean;
  firstCatchedURL=''
  temp_code=''
  
//what do we need to persist in localstorage after a sucessful login....
//meta4 data+ jwt data.
  access_token=''
  decoded_access_token=''
  meta4UserData={}
//************************************ */



//************************************* */
//variable of method to fix sync issue between login dialog in launch and app components
already_launched:false
//************************************* */

  httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };
  helper = new JwtHelperService();
 
  generalAuthCheck(){

    if(localStorage.getItem('access_token')!==null)
{
  if(!this.helper.isTokenExpired(localStorage.getItem('access_token')))
  {
   // //console.log("token has NOT expired..")
    return true
   

  }
  return false
}
return false
  }
 


  fetchJWT = new Observable(observer => {


    if (this.temp_code==undefined){
      
      return observer.next("CODE-NOT-PRESENT")
    }else{
   
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded'
        })
      };
  
      const body = new HttpParams().set('code', this.temp_code).append('client_id', environment.KEYCLOAK_CLIENT_ID).append('grant_type', 'authorization_code').append('redirect_uri', environment.SERVER_FRONT_ADDR);
      const server =  environment.KEYCLOAK_SERVER_ADDR + '/auth/realms/' + environment.KEYCLOAK_REALM + '/protocol/openid-connect/token';

     return this.http.post<any>(server, body, httpOptions).subscribe(
        res =>{
          localStorage.setItem('access_token',res['access_token'])
          //this.access_token=res;
          this.decoded_access_token=this.helper.decodeToken(localStorage.getItem('access_token'))
          
          ////console.log(this.decoded_access_token)
          this.subject_.next('USER-INFO-SET');
          //this.getUserMeta4Data();
          this.fetchMeta4.subscribe(res=>{console.log("META4 CALLED")})//console.log(""))
           return observer.next("JWT-OBTAINED")
      
      }, (error) => {
        observer.error(error);
      });

    }
     

    // When the consumer unsubscribes, clean up data ready for next subscription.
    // return {
    //   unsubscribe() {
    //     navigator.geolocation.clearWatch(watchId);
    //   }
    // };
  });



  fetchMeta4 = new Observable(observer => {
   
    const httpOptions = {
      headers: new HttpHeaders({
        'api-key': 'n9OD5TOQxgzbWII7nl9LPcDOPrSPus5dY8iyAneErHXHbyBzS3IRYyEqXsNqbLOz'
      })
    };


    //const body = new HttpParams().set('code', this.temp_code).append('client_id', environment.KEYCLOAK_CLIENT_ID).append('grant_type', 'authorization_code').append('redirect_uri', environment.SERVER_FRONT_ADDR);
    let user_mail=this.decoded_access_token['email']
    
    user_mail=user_mail.replace('mail.','')


    const fragment =  'operador/?email='+user_mail ;
    
    const server =  environment.SERVER_BACK_ADDR +fragment;
   // //console.log('********************************************************')
   // //console.log('********************************************************')
   // //console.log('*********************************************************Before returning http get inside fetchmeta4 observable server url =',server)
   return this.http.get<any>(server, httpOptions).subscribe(
      res =>{
     //  //console.log("GOT META4 %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DATA")
      // //console.log(res)
       let meta4_user={'descripcion': res['data'][0]['descripcion'],'id': res['data'][0]['id']}
       localStorage.setItem('meta4_user_data',JSON.stringify(meta4_user))
       this.subject_meta4.next("META4-OBTAINED")
       this.subject_oh_behave.next("META4-OBTAINED")
      
       return observer.next('META4-OBTAINED')// this.subject_meta4.next("META4-OBTAINED")
       //return res;
        
    
    }, (error) => {
      //console.log("error!!! in fetchmeta4"+error)
      observer.error(error);
    });
   

  // When the consumer unsubscribes, clean up data ready for next subscription.
  // return {
  //   unsubscribe() {
  //     navigator.geolocation.clearWatch(watchId);
  //   }
  // };
});

  // 'https://webhooks.mongodb-stitch.com/api/client/v2.0/app/nomenclador-fekfu/service/operador/incoming_webhook/operador?email=mragusa@jus.mendoza.gov.ar' \
  // --header 'api-key: n9OD5TOQxgzbWII7nl9LPcDOPrSPus5dY8iyAneErHXHbyBzS3IRYyEqXsNqbLOz'
 testEP()
 {
    
  const httpOptions = {
    headers: new HttpHeaders({
      'api-key': 'n9OD5TOQxgzbWII7nl9LPcDOPrSPus5dY8iyAneErHXHbyBzS3IRYyEqXsNqbLOz'
    })
  };
  const server =  environment.STICH_API_ADDR + 'operador?email=cristiandiaz@jus.mendoza.gov.ar' ;
  return this.http.get<any>(server,httpOptions)
 }

 set_modal_login_sync(already_launched)
 {
  this.already_launched=already_launched
 }
 get_modal_login_sync()
 {
   return this.already_launched
 }


 getBehaviourSubjectMeta(){return this.subject_oh_behave}
  // getUserMeta4Data()
  // {
  //   return this.fetchMeta4;
  // }

  triggerMeta4DataFetch(): Observable<any> 
  {
    return this.fetchMeta4
  }

    getUserMeta4Data()
    {
      return JSON.parse(localStorage.getItem('meta4_user_data'))
    }
  isUserMeta4DataObtained(): Observable<any> 
  {
    return this.subject_meta4.asObservable();
  }

  isUserDataSet(): Observable<any> {
    return this.subject_.asObservable();
}

setFirstCatchedURL(url)
{
  this.firstCatchedURL=url
  this.temp_code=this.firstCatchedURL.split('code=')[1]

  if (this.temp_code==undefined){
    //console.log('not arriving from kc.')
    return "CODE-NOT-SET"
  }else{return "CODE-SET"}
 
}

  getTokenWithTempCode() {

    return this.fetchJWT
 
  }

  getFrontVersion()
  {
    //harcoded for now to test it
    return environment.VERSION
  }
  getUserData() {
    
     // //console.log("returninig"+ this.helper.decodeToken(localStorage.getItem('access_token')))

      return this.helper.decodeToken(localStorage.getItem('access_token'))
  
  }

  getUserDataUserName() {
    return this.getUserData()['preferred_username'];
  }


  logout()
  {
    let logout_url=environment.KEYCLOAK_SERVER_ADDR + '/auth/realms/' + environment.KEYCLOAK_REALM + '/protocol/openid-connect/logout?redirect_uri=' + environment.KEYCLOAK_AUTH_ADDRESS_ENC
   // //console.log(logout_url);
    localStorage.clear();
    
   // alert()
    window.location.replace(logout_url);
  }



  checkTokenExpiry() {

  

    return this.helper.isTokenExpired(localStorage.getItem('access_token'));
  }

 
}
