import { Component } from '@angular/core';
import {AuthService} from './services/auth.service';
import {VersionCheckService} from './services/version-check.service'
import { logging } from 'selenium-webdriver';
import { environment } from './../environments/environment';
import { Observable } from 'rxjs';
import { timer } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  display = false;
  yep = true;
  nope = false;
   numbers = timer(5000, 10*60*1000); //millisecs
   networkCheckerCounter=0
   networkCheckerOKCounter=0
  constructor(private authService:AuthService,private versionCheckService:VersionCheckService){

    let uri_ = window.location.href;
   
    
    
   

    if(this.authService.setFirstCatchedURL(uri_)=="CODE-NOT-SET")
    {
     // //console.log('appcomponent code not set')
      if(! this.authService.generalAuthCheck())
      {
        this.display=true
        this.authService.set_modal_login_sync(true)
      }
    } 
    if(this.authService.setFirstCatchedURL(uri_)=="CODE-NOT-SET")
    {
     // //console.log('appcomponent code not set')
      if(! this.authService.generalAuthCheck())
      {
        this.display=true
        this.authService.set_modal_login_sync(true)
      }
    }
    else{
      this.authService.isUserDataSet().subscribe(res=>
        {
          ////console.log("appcomponent subscribable response."+res)
          if(res=="USER-INFO-SET")
          {
            this.display=false
            this.authService.set_modal_login_sync(true)
          }
        })
    }

    

    //this runs evey time the SPA is loaded ANYWHERE in ANY ROUTE. and we check if it comes from keycloak (has code param) or it has not code and its just a refresh or f5 kinda.
    //in the case of refresh, then we check for the presence of a stored JWT, case contrary. we kick out the user to a log in screen.
    //some sort of check like this one is required in http interceptor, more precisely 
   ////console.log('APPCOMPONENT>>>>>>>>>>>>>>>>>>')
  //  //console.log(this.authService.generalAuthCheck())
   // //console.log('APPCOMPONENT>>>>>>>>>>>>>>>>>>')
    //alert("goin to call testep to try to fetch data directly from stitch PUMMM")
    //this.authService.testEP().subscribe(res=>//console.log("STITCH ANSWER"+res))
   
  //alert(uri_)
 
    
  }
  startNetworkChecker(){

    if((this.networkCheckerCounter-this.networkCheckerOKCounter)>2)
    {
      
      alert(environment.NETWORK_CONN_ISSUE_MESSAGE)
      this.networkCheckerCounter=0
      this.networkCheckerOKCounter=0
    }
    this.networkCheckerCounter++;
  this.versionCheckService.checkBackendReach().subscribe(r=>
    {
      ////console.log(r)
      this.networkCheckerOKCounter++;
    })
  }

  
  

  
  ngOnInit(){

    this.numbers.subscribe(x => this.startNetworkChecker());

    
  }

  login()
  {
    
    //alert(environment.KEYCLOAK_LOGIN_ADDR)
    window.location.href =environment.KEYCLOAK_AUTH_ADDRESS;
  }
 
}
