import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ConfigService {

//   constructor() { }

//   getAuthAddress()
//   {
//     return 'https://auth.pjm.gob.ar/auth/realms/devops/protocol/openid-connect/auth?client_id=ged&nonce=te7s5t9t&response_mode=fragment&response_type=code&login=true&redirect_uri=http://localhost:8102/'
//   }

// }
 

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  getAuthAddress()
  {
    return 'https://auth.pjm.gob.ar/auth/realms/internals/protocol/openid-connect/auth?client_id=med&nonce=te7s5t9t&response_mode=fragment&response_type=code&login=true&redirect_uri=http://localhost:4201/'
  }

}
 