<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<p-dialog header="Iniciar Sesión" [(visible)]="display" [(modal)]="yep" [(closeOnEscape)]="nope" [(closable)]="nope">
  <mat-card class="example-card">
 
  <img mat-card-image src="assets/login.png" alt="session" style="width:100px;height:100px;">
  
  <mat-card-actions>
     <button mat-raised-button color="primary" (click)="login()">Ir al Login</button>
    
  </mat-card-actions>
</mat-card>

</p-dialog>


<router-outlet><app-spinner></app-spinner></router-outlet>