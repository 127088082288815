import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService} from '../../../services/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy,OnInit {
  mobileQuery: MediaQueryList;
  userName=''
  userSurname=''
  rol=''

  private _mobileQueryListener: () => void;

  constructor(
    private authService:AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngOnInit()
  {
    
    if(this.authService.generalAuthCheck())
    {
     
      //means we already have some jwt set from some previous login.
      this.userName=this.authService.getUserData()['name'].substring(0,20)
      this.rol="[somerol]"
    }else{

          this.authService.isUserDataSet().subscribe(resp=>{
          
          this.userName=this.authService.getUserData()['name'].substring(0,20)
          this.rol="[somerol]"
          //alert(this.userName)
          })
        }
  }

  logout()
  {
    //console.log("logginout")
    this.authService.logout()
  }
}
