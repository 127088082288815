import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/main',
        pathMatch: 'full'
      },
      {
        path: 'escritos',
        redirectTo: '/main/escritos',
        pathMatch: 'full'
      },
      {
        path: 'document-download',
        redirectTo: '/main/document-download',
        pathMatch: 'full'
      },
      {
        path: 'info-dash',
        redirectTo: '/main/info-dash',
        pathMatch: 'full'
      },
      {
        path: 'session_state',
        redirectTo: '/main',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'main',
        loadChildren: () => import('./components/components.module').then(m=> m.ComponentsModule)
      }
    ]
  }
];
